<template lang='pug'>
div
  base-header(class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success")
  b-container(fluid class="mt--9")
    b-row
      b-col
        el-table(class="table-responsive table" header-row-class-name="thead-light" :data='vms')
          el-table-column(label="ID" prop="id")
          el-table-column(label="secret" prop="secret")
          el-table-column(label="Active" prop="active")
            template(v-slot="{row}")
              b-checkbox(disabled :value='row.active')
          el-table-column(label="Receipt" prop="receipt_enabled")
            template(v-slot="{row}")
              b-checkbox(disabled :value='row.receipt_enabled')
          el-table-column(label="Description" prop="description")
</template>
<script>
import { Table, TableColumn} from 'element-ui'
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      vms: []
    }
  },
  async beforeMount() {
      let response = await this.axios.get(`/partner/vms/`)
      this.vms = response.data
  }
}
</script>
<style>
</style>
